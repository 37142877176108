<template>
  <div class="wrapper">
    <div class="position">
      <van-icon name="location-o" />
      <span v-if="$store.state.LocationMessage">
        {{ $store.state.LocationMessage.city }}
      </span>
      <span v-else> 请求定位中 </span>
    </div>
    <div class="box">
      <div
        @click="$router.push('/home/agrometeorologicalForecast')"
        v-if="locationdata"
      >
        <van-row class="row" type="flex" align="center">
          <van-col class="temp">{{ weatherDate.temp }}<sup>℃</sup></van-col>
          <van-col span="5" class="flex">
            <van-image
              width="1.5em"
              height="1.5em"
              fit="cover"
              :src="
                'http://image.nmc.cn/assets/img/w/40x40/4/' +
                weatherDate.img +
                '.png'
              "
            />
            {{ weatherDate.weather }}
          </van-col>
          <van-col class="weather-item">
            <span>湿度</span>
            <span>{{ weatherDate.hum }}%</span>
          </van-col>
          <van-col class="weather-item">
            <span>{{ weatherDate.wd === "9999" ? "-" : weatherDate.wd }}</span>
            <span>{{ weatherDate.ws === "9999" ? "-" : weatherDate.ws }}</span>
          </van-col>
        </van-row>
        <van-row style="height: 50%"></van-row>
      </div>
      <van-loading v-else class="loading" />
      <van-notice-bar
        left-icon="volume-o"
        :text="warningText"
        scrollable
        color="#1989fa"
        background="#ecf9ff"
        mode="link"
        @click="clickNotice"
      >
      </van-notice-bar>
    </div>
  </div>
</template>
<script>
import { Col, Row, Image, NoticeBar, Icon, Loading } from "vant";
import { getLivingWeather } from "@/service/livingWeather.js";
import { getWarning } from "@/service/warning.js";
import dayjs from "dayjs";
export default {
  name: "LivingWeather",
  data() {
    return {
      weatherDate: {},
      dayjs: dayjs,
      warningText: "",
      locationdata: null
    };
  },
  components: {
    VanCol: Col,
    VanRow: Row,
    VanImage: Image,
    VanNoticeBar: NoticeBar,
    VanIcon: Icon,
    VanLoading: Loading
  },
  watch: {
    "$store.state.LocationMessage": function (newValue) {
      this.locationdata = newValue;
      this.reset(newValue.lon, newValue.lat);
    }
  },
  created() {
    if (!this.$store.state.LocationMessage) {
      return;
    }
    this.locationdata = this.$store.state.LocationMessage;
    this.reset(
      this.$store.state.LocationMessage.lon,
      this.$store.state.LocationMessage.lat
    );
  },
  mounted() {},
  methods: {
    clickNotice() {
      this.$router.push("/home/Warning");
    },
    reset(lng, lat) {
      getLivingWeather(lng, lat).then((res) => {
        this.weatherDate = res.data.liveandate;
      });
      getWarning(lng, lat).then((res) => {
        if (res.data.warm.w && res.data.warm.w.length !== 0) {
          this.warningText = res.data.warm.w[0].title;
        }
      });
    }
  }
};
</script>
<style lang='scss' scoped>
$color: #001f90;
$font-color: #fff;
.wrapper {
  background: $color;
  color: $font-color;
  display: flex;
  height: 80px;
  .position {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    span {
      font-weight: 600;
      padding: 2px 6px;
    }
  }
  .box {
    width: 77%;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    padding: 8px;
  }
  .temp {
    font-size: 5.2vw;
    sup {
      font-size: 1.26667vw;
      top: -1.5em;
    }
  }
  .weather-item {
    span {
      margin: 4px 0;
    }
  }
}
.flex {
  display: flex;
  align-items: center;
}
.van-notice-bar {
  height: 30px;
  width: 87%;
  margin-top: 1.5vw;
}
</style>
