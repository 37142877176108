<template>
  <div class="functionalarea">
    <div class="functionalarea-box">
      <div class="functionalarea-img" @click="Farmland()">
        <img src="../../assets/homeImg/plot.svg" alt="" />
        <div class="functionalarea-word">添地块</div>
      </div>
      <div class="functionalarea-img">
        <img
          src="../../assets/homeImg/farming.svg"
          alt=""
          @click="farmworkType()"
        />
        <div class="functionalarea-word">记农事</div>
      </div>
      <div class="functionalarea-img" style="display:none;">
        <img
          src="../../assets/homeImg/output.svg"
          alt=""
          @click="$router.push('/home/measureOutput')"
        />
        <div class="functionalarea-word">测产量</div>
      </div>
      <div class="functionalarea-img">
        <img
          src="../../assets/homeImg/harvest.svg"
          alt=""
          @click="$router.push('/home/recordHarvest')"
        />
        <div class="functionalarea-word">记收成</div>
      </div>
      <div class="functionalarea-img">
        <img src="../../assets/homeImg/expert.svg" alt="" @click="expert()" />
        <div class="functionalarea-word">问专家</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMySelectList } from "@/service/farmworkType";
export default {
  name: "Functionalarea",
  data() {
    return {
      show: false,
      lng: "",
      lat: "",
      farmlandName: "",
      irrigation: "",
      irrigation_id: "",
      farmlandArea: "",
      contactPerson: "",
      contactNumber: "",
      fileList: []
    };
  },
  methods: {
    Farmland() {
      this.$store.commit("setLng", this.lng);
      this.$store.commit("setLat", this.lat);
      this.$store.commit("setfarmlandName", this.farmlandName)
      this.$store.commit("setirrigation", this.irrigation);
      this.$store.commit("setirrigation_id", this.irrigation_id);
      this.$store.commit("setfarmlandArea", this.farmlandArea);
      this.$store.commit("setcontactPerson", this.contactPerson);
      this.$store.commit("setcontactNumber", this.contactNumber);
      this.$store.commit("setfileList", this.fileList);
      this.$router.push({
        path: "/addfarmland"
      });
    },
    expert() {
      this.$router.push({
        path: "/expert"
      });
    },
    farmworkType() {
      getMySelectList().then(res => {
        if (res.data === []) {
          this.show = true;
          this.$store.commit("setShow", true);
        } else {
          this.$router.push({
            path: "/farmworktypedetail"
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.functionalarea {
  margin-top: 10px;
  margin-bottom: 10px;
  .functionalarea-box {
    display: flex;
    justify-content: space-around;
    margin: 30px;
    .functionalarea-img {
      width: 50px;
      .functionalarea-word {
        margin: 2px;
        width: 60px;
      }
    }
  }
}
</style>
