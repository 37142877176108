<template>
  <div class="newlist" ref="box" @scroll.passive="scrolls">
    <div class="newlist-title">新闻列表</div>
    <ul>
      <div
        class="new-list"
        v-for="(item, index) in countData"
        :key="index"
        @click="getListId(item.id)"
      >
        <div class="new-list-cnt">
          <div class="new-list-left">
            <div class="new-list-img">
              <img
                :src="item.imageUrl ? item.imageUrl.split(',')[0] : ''"
                alt=""
                style="width: 102px"
              />
            </div>
            <div class="new-list-word">
              <div class="new-list-title">{{ item.title }}</div>
              <div class="new-list-date">{{ item.publishTime }}</div>
            </div>
          </div>
          <div class="new-list-right">
            <div class="new-list-kong"></div>
          </div>
        </div>
      </div>
    </ul>
    <p class="noMore" v-if="noMore">没有更多的数据了</p>
  </div>
</template>

<script>
import { Notify } from "vant";
import { getNewList } from "../../service/Newlist";
export default {
  name: "NewList",
  data() {
    return {
      list: [],
      cou: 3, // 默认显示几条数据
      loading: true,
      pageNum: 1,
      pagesize: 999,
      userId: 20201028001001
    };
  },
  components: {
    [Notify.Component.name]: Notify.Component
  },
  computed: {
    noMore() {
      // 判断加载条数是否大于列表数据长度
      return this.cou > this.list.length;
    },
    disabled() {
      // 加载完成
      return this.loading || this.noMore;
    },
    countData() {
      // 计算属性使用切片生成新数组
      let data = [];
      // 大于三条，使用切片，返回新数组
      if (this.list.length > 3) {
        data = this.list.slice(0, this.cou);
        return data;
      } else {
        // 否则使用原来数组，不进行切片处理
        data = this.list;
        return data;
      }
    }
  },
  mounted() {
    this.getList();
    window.addEventListener("scroll", this.scrolls, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolls, true);
  },
  methods: {
    getList() {
      getNewList(this.userId, this.pageNum, this.pagesize).then((res) => {
        const data = res.data.pageData;
        this.list = data;
      });
    },
    getListId(id) {
      this.$router.push({
        path: `/newdetail/${id}&${this.list}`
      });
    },
    scrolls() {
      // 滚动条到顶部的距离
      const scrollTop = this.$refs.box.getBoundingClientRect().top * -1;
      // 页面可是高度
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 盒子的高度
      const scrollHeight = this.$refs.box.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        this.cou += 3;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.newlist {
  margin: 10px 20px;
  padding-bottom: 60px;
  .newlist-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .new-list {
    width: 100%;
    border: 2px solid rgba(77, 77, 77, 0.43);
    border-radius: 15px;
    margin-bottom: 20px;
    .new-list-cnt {
      position: relative;
      margin-top: 10px;
      margin-left: 2px;
      display: flex;
      .new-list-left {
        display: flex;
        .new-list-img {
          // width: 38.66667vw;
          margin-left: 9px;
        }
        .new-list-word {
          margin-left: 5px;
          .new-list-title {
            width: 100%;
            font-size: 16px;
          }
          .new-list-date {
            font-size: 14px;
            margin-top: 5vw;
          }
        }
      }
      .new-list-right {
        .new-list-read {
          font-size: 14px;
          width: 70px;
          position: absolute;
          top: 79px;
          right: -2px;
        }
      }
    }
  }
  .noMore {
    text-align: center;
  }
}
</style>
