<template>
  <div class="home">
    <div class="gw-ntgj-home-top">
      <living-weather />
    </div>
    <index-Functionalarea></index-Functionalarea>
    <index-solarterms></index-solarterms>
    <agency></agency>
    <index-newlist></index-newlist>
    <index-menu></index-menu>
  </div>
</template>

<script>
// @ is an alias to /src
import store from "../../store/index";
import IndexMenu from "../menu/menu";
import IndexSolarterms from "./solarterms";
import IndexNewlist from "./Newlist";
import { getUserByTel } from "../../service/loginService";
import LivingWeather from "@/components/home/LivingWeather";
import IndexFunctionalarea from "./functionalarea";
import agency from "./agency.vue";
import { getLocation } from "@/service/locationService.js";
export default {
  name: "Home",
  components: {
    IndexSolarterms,
    IndexNewlist,
    IndexMenu,
    LivingWeather,
    IndexFunctionalarea,
    agency
  },
  methods: {
    init() {
      const token = localStorage.getItem("token");
      const tel = localStorage.getItem("tel");
      if (token) {
        // 获取用户信息
        store.commit("setToken", token);
        getUserByTel(tel).then((res) => {
          store.commit("setUser", res);
        });
      }
    }
  },
  created() {
    this.init()
    const store = this.$store;
    getLocation().then((res) => {
      store.commit("setLocation", res.data);
    });
  }
};
</script>
