<template>
  <div class="solarterms-cnt">
    <div class="solarterms-titel">二十四节气</div>
    <div class="solarterms-box">
      <div class="solarterms-box-title">
        <div class="solarterms">{{ jq }}</div>
        <div class="solarterms-day">第{{ day }}天</div>
      </div>
      <div class="solarterms-box-cnt">
        <div
          class="solarterms-box-cnt-word"
          v-for="(item, index) in info"
          :key="index"
        >
          <div class="solarterms-box-cnt-word-ctn">
            {{ item }}
          </div>
        </div>
        <div class="solarterms-box-cnt-img">
          <img :src="solartermsImg" alt="" style="width:100%;height:100%" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSolarTerms } from "../../service/solarterms";
import { Notify } from "vant";
export default {
  name: "Solarterms",
  data() {
    return {
      jq: "",
      day: "",
      info: [],
      itemImg: ""
    };
  },
  components: {
    [Notify.Component.name]: Notify.Component
  },
  computed: {
    solartermsImg() {
      return require(`../../assets/homeImg/${this.itemImg}.png`)
    }
  },
  mounted() {
    this.getSolarCnt();
  },
  methods: {
    getSolarCnt() {
      getSolarTerms().then(res => {
        const data = res.data;
        this.jq = data.jq;
        this.day = data.days;
        this.itemImg = data.jqpinyin
        if (data.info === "") {
          Notify({ type: "primary", message: "暂无数据" });
        } else {
          this.info = data.info;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.solarterms-cnt {
  margin: 10px 20px;
  .solarterms-titel {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .solarterms-box {
    width: 100%;
    border: 2px solid rgba(77, 77, 77, 0.43);
    border-radius: 15px;
    min-height: 130px;
  }
  .solarterms-box-title {
    display: flex;
    margin-top: 10px;
    margin-left: 15px;
    .solarterms {
      font-size: 16px;
      font-weight: 600;
      margin-right: 15px;
    }
    .solarterms-day {
      font-size: 15px;
      color: #000;
    }
  }
  .solarterms-box-cnt {
    position: relative;
    .solarterms-box-cnt-word {
      margin-top: 10px;
      margin-left: 15px;
      width: 61%;
      line-height: 18px;
      .solarterms-box-cnt-word-ctn {
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
    .solarterms-box-cnt-img {
      width: 100px;
      height: 100px;
      position: absolute;
      top: -5.4vw;
      left: 224px;
    }
  }
}
</style>
