<template>
    <div class="agency">
        <ul>
            <li style="margin-top:10px" v-for="item in list" :key="item.id">
                <div class="box">
                    <div class="left">
                        <img :src="item.cropImage" alt="">
                    </div>
                    <div class="right" @click="todetail(item.id)">
                        <div class="right-info">
                            <strong>{{item.crop_name}}</strong>
                            <span>{{item.farm_name}}</span>
                        </div>
                        <div class="right-today" v-if="item.currentCropLife">
                            <img src="../../assets/image/Field/组 4.png" alt="">
                            <span
                                v-for="items in item.currentCropLife.phenologyOperList.slice(0,3)" :key="items.id">{{ items.name }}</span>
                        </div>
                        <div class="right-today">
                            <img src="../../assets/image/Field/组 2.png" alt="">
                            <span>{{ item.expect_harvest_time | dateFormat}}
                            <span>{{ item.to_harvest_days }}</span>天后</span>
                        </div>
                    </div>
                    <div class="info">
                        <!-- <div class="info-content">一号大鹏</div> -->
                        <div class="info-img">
                            <img src="../../assets/image/Field/footprint.png" alt="">
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { landToDo } from "../../service/Addfarmland"
export default {
    data() {
        return {
            list: []
        };
    },
    mounted() {
        landToDo().then((res) => {
            this.list = res.data
        })
    },
    methods: {
        todetail(id) {
            this.$router.push({ path: `/server/cropDetail/${id}` });
        }
    }
};
</script>

<style lang="scss" scoped>
.agency{
    margin: 2.66667vw 5.33333vw
    // li{
    //     border-radius: 5px;
    // }
}
.box{
    border: 1px solid #ccc;
    display: flex;
    width: 90vw;
    .left{
        width: 30%;
        img{
            width: 100%;
        }
    }
    .right{
        margin-left: 5px;
        width: 180px;
        .right-info{
            margin-top: 5px;
            display: flex;
            strong{
                width: 30%;
            }
            span{
                width: 76px;
                margin-left: 10px;
                overflow: hidden; //超出文本隐藏
                white-space: nowrap; //不换行，只显示一行
                text-overflow:ellipsis; //超出部分省略号显示
                -o-text-overflow:ellipsis;
            }
        }
        .right-today{
            margin-top: 5px;
            width: 180px;
            display: flex;
            align-items: center;
            overflow: hidden; //超出文本隐藏
            white-space: nowrap; //不换行，只显示一行
            text-overflow:ellipsis; //超出部分省略号显示
            -o-text-overflow:ellipsis;
            img{
                width: 20px;
                margin-right: 10px;
            }
            span{
                margin-left: 5px;
                line-height: 24px;
                font-size: 15px;
            }
        }
    }
    .info{
        width: 15%;
        display: flex;
        flex-direction: column;
        .info-img{
            width: 30px;
            img{
                margin: 20px 0 0 15px;
                width: 100%;
            }
        }
    }
}
</style>